import Vue from 'vue';
import apiKeysServices from './api/api-keys';
import appServices from './api/app';
import appLogsServices from './api/app-logs';
import appSystemServices from './api/app-system';
import appSystemsServices from './api/app-systems';
import appSystemWebApisServices from './api/app-system-web-apis';
import authServices from './api/auth';
import callLogsServices from './api/call-logs';
import contactIdMappingsServices from './api/contact-id-mappings';
import documentsServices from './api/documents';
import dynamicFormServices from './api/dynamic-form';
import fieldMappingServices from './api/field-mapping';
import httpMethodLookupServices from './api/http-method-lookup';
import integrationCallsServices from './api/integration-calls';
import integrationTasksServices from './api/integration-tasks';
import locationsServices from './api/locations';
import meServices from './api/me';
import onlinePaymentTransactionServices from './api/online-payment-transaction';
import processesServices from './api/processes';
import sequencenumbersServices from './api/sequencenumbers';
import tdg_CompaniesServices from './api/tdg-companies';
import tdg_DocumentsServices from './api/tdg-documents';
import tdg_MapsServices from './api/tdg-maps';
import tdg_OrganisationsServices from './api/tdg-organisations';
import tdg_PDFServices from './api/tdg-pdf';
import tdg_UsersServices from './api/tdg-users';
import tenantsServices from './api/tenants';
import usersServices from './api/users';

export default () => ({
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  appSystems: { ...appSystemsServices(Vue.$service) },
  appSystemWebApis: { ...appSystemWebApisServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  callLogs: { ...callLogsServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  httpMethodLookup: { ...httpMethodLookupServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  onlinePaymentTransaction: { ...onlinePaymentTransactionServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  tdg_Companies: { ...tdg_CompaniesServices(Vue.$service) },
  tdg_Documents: { ...tdg_DocumentsServices(Vue.$service) },
  tdg_Maps: { ...tdg_MapsServices(Vue.$service) },
  tdg_Organisations: { ...tdg_OrganisationsServices(Vue.$service) },
  tdg_PDF: { ...tdg_PDFServices(Vue.$service) },
  tdg_Users: { ...tdg_UsersServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
});
