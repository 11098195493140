// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import PostUserRequestModel from '../../model/post-user-request-model';
import GetMyRegistrationTypeRequestModel from '../../model/get-my-registration-type-request-model';
import Tdg_UserModel from '../../model/tdg-user-model';

export default (service: Servicelayer) => ({
  /**  */
  async postUser(request: PostUserRequestModel, config?: RequestConfig) {
    const requestDTO = PostUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-user'] ?? 'Tdg_Users';
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        registrationType: requestDTO.registrationType,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_UserModel.toModel(dto));
  },
  /**  */
  async getMyRegistrationType(request: GetMyRegistrationTypeRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-my-registration-type'] ?? 'Tdg_Users/MyRegistrationType';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_UserModel.toModel(dto));
  },
});
