// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostOrganisationRequestModel from '../../model/post-organisation-request-model';
import PostOrganisationRequestDTO from '../dto/post-organisation-request-dto';
import dtoSchema from '../schemas/post-organisation-request-schema';

export default abstract class PostOrganisationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostOrganisationRequestDTO>) {
    super();
    if (data) {
      this.organisationName = data.organisationName;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.membersCount = data.membersCount;
      this.organisationContactSalutation = data.organisationContactSalutation;
      this.organisationContactFirstName = data.organisationContactFirstName;
      this.organisationContactLastName = data.organisationContactLastName;
      this.organisationContactPhoneNumber = data.organisationContactPhoneNumber;
      this.organisationContactEmail = data.organisationContactEmail;
      this.organisationStreet = data.organisationStreet;
      this.organisationHouseNumber = data.organisationHouseNumber ?? undefined;
      this.organisationZIP = data.organisationZIP;
      this.organisationCity = data.organisationCity;
      this.isActive = data.isActive;
      this.acceptConditions = data.acceptConditions;
      this.organisationUserId = data.organisationUserId ?? undefined;
      this.isSupporter = data.isSupporter ?? undefined;
    }
  }
  /**
  * @minimum 1
  */
  organisationName?: string;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  * @type {int32}
  */
  membersCount?: number;
  /**
  * @type {int32}
  */
  organisationContactSalutation?: 0 | 1 | 2; // SalutationDTO
  /**
  * @minimum 1
  */
  organisationContactFirstName?: string;
  /**
  * @minimum 1
  */
  organisationContactLastName?: string;
  /**
  * @minimum 1
  */
  organisationContactPhoneNumber?: string;
  /**
  * @minimum 1
  */
  organisationContactEmail?: string;
  /**
  * @minimum 1
  */
  organisationStreet?: string;
  /**
  */
  organisationHouseNumber?: string;
  /**
  * @minimum 1
  */
  organisationZIP?: string;
  /**
  * @minimum 1
  */
  organisationCity?: string;
  /**
  */
  isActive?: boolean;
  /**
  */
  acceptConditions?: boolean;
  /**
  * @type {int64}
  */
  organisationUserId?: number;
  /**
  */
  isSupporter?: boolean;

  static toModel(dto: DeepPartial<PostOrganisationRequestDTO>): PostOrganisationRequestModel;
  static toModel(dto: DeepPartial<PostOrganisationRequestDTO> | undefined | null): PostOrganisationRequestModel | undefined;
  static toModel(dto: DeepPartial<PostOrganisationRequestDTO> | undefined | null): PostOrganisationRequestModel | undefined {
    return dto ? new PostOrganisationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostOrganisationRequestModel> | PostOrganisationRequestModel): PostOrganisationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      organisationName: unwrapped.organisationName,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      membersCount: unwrapped.membersCount,
      organisationContactSalutation: unwrapped.organisationContactSalutation,
      organisationContactFirstName: unwrapped.organisationContactFirstName,
      organisationContactLastName: unwrapped.organisationContactLastName,
      organisationContactPhoneNumber: unwrapped.organisationContactPhoneNumber,
      organisationContactEmail: unwrapped.organisationContactEmail,
      organisationStreet: unwrapped.organisationStreet,
      organisationHouseNumber: unwrapped.organisationHouseNumber,
      organisationZIP: unwrapped.organisationZIP,
      organisationCity: unwrapped.organisationCity,
      isActive: unwrapped.isActive,
      acceptConditions: unwrapped.acceptConditions,
      organisationUserId: unwrapped.organisationUserId,
      isSupporter: unwrapped.isSupporter,
    } as PostOrganisationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostOrganisationRequestModel, can not map to PostOrganisationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
