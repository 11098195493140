// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Tdg_MapItemModel from '../../model/tdg-map-item-model';
import Tdg_MapItemDTO from '../dto/tdg-map-item-dto';
import dtoSchema from '../schemas/tdg-map-item-schema';

export default abstract class Tdg_MapItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Tdg_MapItemDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.title = data.title ?? undefined;
      this.type = data.type ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {double}
  */
  latitude?: number;
  /**
  * @type {double}
  */
  longitude?: number;
  /**
  */
  title?: string;
  /**
  */
  type?: string;

  static toModel(dto: DeepPartial<Tdg_MapItemDTO>): Tdg_MapItemModel;
  static toModel(dto: DeepPartial<Tdg_MapItemDTO> | undefined | null): Tdg_MapItemModel | undefined;
  static toModel(dto: DeepPartial<Tdg_MapItemDTO> | undefined | null): Tdg_MapItemModel | undefined {
    return dto ? new Tdg_MapItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Tdg_MapItemModel> | Tdg_MapItemModel): Tdg_MapItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      latitude: unwrapped.latitude,
      longitude: unwrapped.longitude,
      title: unwrapped.title,
      type: unwrapped.type,
    } as Tdg_MapItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Tdg_MapItemModel, can not map to Tdg_MapItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
