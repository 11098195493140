// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DownloadTdgPDFRequestModel from '../../model/download-tdg-pdf-request-model';
import DownloadTdgPDFRequestDTO from '../dto/download-tdg-pdf-request-dto';
import dtoSchema from '../schemas/download-tdg-pdf-request-schema';

export default abstract class DownloadTdgPDFRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DownloadTdgPDFRequestDTO>) {
    super();
    if (data) {
      this.companyName = data.companyName ?? undefined;
      this.companyJobs = data.companyJobs ?? undefined;
      this.companyApprenticeships = data.companyApprenticeships ?? undefined;
      this.companyAdress = data.companyAdress ?? undefined;
      this.companyContact = data.companyContact ?? undefined;
    }
  }
  /**
  */
  companyName?: string;
  /**
  */
  companyJobs?: string;
  /**
  */
  companyApprenticeships?: string;
  /**
  */
  companyAdress?: string;
  /**
  */
  companyContact?: string;

  static toModel(dto: DeepPartial<DownloadTdgPDFRequestDTO>): DownloadTdgPDFRequestModel;
  static toModel(dto: DeepPartial<DownloadTdgPDFRequestDTO> | undefined | null): DownloadTdgPDFRequestModel | undefined;
  static toModel(dto: DeepPartial<DownloadTdgPDFRequestDTO> | undefined | null): DownloadTdgPDFRequestModel | undefined {
    return dto ? new DownloadTdgPDFRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DownloadTdgPDFRequestModel> | DownloadTdgPDFRequestModel): DownloadTdgPDFRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      companyName: unwrapped.companyName,
      companyJobs: unwrapped.companyJobs,
      companyApprenticeships: unwrapped.companyApprenticeships,
      companyAdress: unwrapped.companyAdress,
      companyContact: unwrapped.companyContact,
    } as DownloadTdgPDFRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DownloadTdgPDFRequestModel, can not map to DownloadTdgPDFRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
