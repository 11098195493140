// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/tdg-map-item-schema',
  type: 'object',
  required: ['id', 'latitude', 'longitude'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    latitude: {
      type: 'number',
    },
    longitude: {
      type: 'number',
    },
    title: {
      type: 'string',
      nullable: true,
    },
    type: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
