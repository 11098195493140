<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="il-widget-tester" :class="{ [_.casing.toKebabCase(title)]: true }">
    <p-row column no-gutters nowrap>
      <p-col shrink>
        <h2>{{ $tAlt(title, title) }}</h2>
      </p-col>
      <p-col class="il-widget-tester-section configs" shrink>
        <p-card>
          <slot name="configs" :is-widget-embed-info-visible="isWidgetEmbedInfoVisible">
            <p-row>
              <slot name="prepend-configs"></slot>
              <template v-for="key in Object.keys(innerWidgetConfig)">
                <slot :name="key" :config="innerWidgetConfig[key]">
                  <p-col
                    v-if="!innerWidgetConfig[key].hidden"
                    :key="key"
                    class="config-entry "
                    :class="{ [key]: true }"
                    xs12
                    sm6
                    md4
                    lg3
                  >
                    <p-select
                      v-if="_.isArray(innerWidgetConfig[key].items)"
                      v-model="innerWidgetConfig[key].value"
                      :items="innerWidgetConfig[key].items"
                      :label="getConfigLabel(key, innerWidgetConfig[key])"
                      :clearable="!innerWidgetConfig[key].required"
                      :required="innerWidgetConfig[key].required"
                      :disabled="innerWidgetConfig[key].disabled"
                    ></p-select>
                    <p-auto-complete
                      v-else-if="_.isArray(innerWidgetConfig[key].suggestions)"
                      v-model="innerWidgetConfig[key].value"
                      :items="innerWidgetConfig[key].items"
                      :label="getConfigLabel(key, innerWidgetConfig[key])"
                      :required="innerWidgetConfig[key].required"
                      :disabled="innerWidgetConfig[key].disabled"
                    ></p-auto-complete>
                    <p-number-field
                      v-else-if="innerWidgetConfig[key].type === 'number'"
                      v-model="innerWidgetConfig[key].value"
                      :label="getConfigLabel(key, innerWidgetConfig[key])"
                      no-steppers
                      :required="innerWidgetConfig[key].required"
                      :disabled="innerWidgetConfig[key].disabled"
                    ></p-number-field>
                    <p-text-field
                      v-else-if="_.isEmpty(innerWidgetConfig[key].type) || innerWidgetConfig[key].type === 'string'"
                      v-model="innerWidgetConfig[key].value"
                      :label="getConfigLabel(key, innerWidgetConfig[key])"
                      :required="innerWidgetConfig[key].required"
                      :disabled="innerWidgetConfig[key].disabled"
                    ></p-text-field>
                   </p-col>
                </slot>
              </template>
              <slot name="append-configs"></slot>
              <p-col xs12 justify-end align-end>
                <p-button :disabled="widgetIsLoading" @click="openWidget()">
                  {{ $t('core.app.ok') }}
                </p-button>
                <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                  <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                    dataset_linked
                  </p-icon>
                  <p-icon right>
                    {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                  </p-icon>
                </p-button>
              </p-col>
            </p-row>
          </slot>

          <div>
            <p-alert :value="errorMessage" type="error" class="my-4">
              <pre>{{ errorMessage }}</pre>
            </p-alert>
          </div>
        </p-card>
      </p-col>
      <p-col shrink>
        <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0 il-widget-tester-section embedding">
          <div v-if="!link">
            {{ $t('widget.configPages.noLinkMessage') }}
          </div>
          <template v-else>
            <p-col shrink>
              <p-row align-center>
                <p-col shrink>
                  <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
                </p-col>
                <p-col grow>
                  <p-row dense>
                    <p-button secondary @click="_.copyToClipboard($refs.link)">
                      <p-icon>content_copy</p-icon>
                    </p-button>
                  </p-row>
                </p-col>
              </p-row>
            </p-col>
            <p-divider class="mb-4"></p-divider>
            <p-col>
              <p-row>
                <p-col grow class="overflow-auto-x">
                  <p-code ref="widgetEmbedCode" lang="html">{{ escapedWidgetConfigSnippet }}</p-code>
                </p-col>
                <p-col shrink :xs12="!$media.isDesktop">
                  <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-col>
              </p-row>
            </p-col>
          </template>
        </p-card>
      </p-col>
      <template v-if="link">
        <p-col>
          <div id="widget-test-mount" class="fill-height fill-width pt-4">
            <!-- WIDGET RENDERED HERE -->
          </div>
        </p-col>
      </template>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import _ from '@glittr/frontend-core/src/utils';
  import type { WidgetConfig, WidgetConfigEntry } from './il-widget-tester.vue.model';

  export default Vue.extend({
    name: 'IlWidgetTester',
    props: {
      value: { type: Object, default: () => ({}) },
      title: { type: String, default: 'Widget Tester' },
      widgetHost: { type: String, default: `${window.location.protocol}//${window.location.host}` },
      loading: { type: Boolean, default: false },
      noLanguageConfig: { type: Boolean, default: false },
    },
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      errorMessage: undefined as string | undefined,
      widgetEmbedSnippet: undefined as string | undefined,
      link: undefined as string | undefined,
      host: `${window.location.protocol}//${window.location.host}`,
      widgetIsLoading: false,
      innerWidgetConfig: {} as WidgetConfig,
    }),
    computed: {
      escapedWidgetConfigSnippet(): string | undefined {
        return this.widgetEmbedSnippet
          ?.replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      },
      languages(): { id: string, caption: string, description: string }[] {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`core.page.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler() {
          this.innerWidgetConfig = {
            // Make sure that the defaults come first in ui-order
            'mount-id': {},
            language: {},
            route: {},
            ...this.value,
          };

          // Defaults

          this.innerWidgetConfig['mount-id'] = {
            label: this.$t('widget.configPages.mountId'),
            value: 'widget-mount',
            hidden: true,
            required: true,
            ...this.innerWidgetConfig['mount-id'],
          };

          this.innerWidgetConfig.language = {
            label: this.$t('page.settings.title.languageSettings'),
            value: 'de',
            items: this.languages,
            hidden: this.noLanguageConfig,
            ...this.innerWidgetConfig.language,
          };

          this.innerWidgetConfig.route = {
            label: this.$t('widget.configPages.route'),
            hidden: true,
            required: true,
            ...this.innerWidgetConfig.route,
          };

          Object.keys(this.innerWidgetConfig).forEach((key) => {
            this.$set((this.innerWidgetConfig as any)[key], 'value', (this.innerWidgetConfig as any)[key]?.value ?? undefined);
          });
        },
      },
    },
    mounted() {
      const queries = this.$routerUtils.getQueryParams();
      Object.keys(this.innerWidgetConfig).forEach((key) => {
        const queryName = this.$config.getQueryNameFromConfigKey(key as any);
        if (!this._.isNil(queries[queryName])) {
          (this.innerWidgetConfig as any)[key].value = queries[queryName];
        }
      });
    },
    methods: {
      getConfigLabel(key: string, entry: WidgetConfigEntry) {
        const label = entry.label ?? Vue._.casing.toTitleCase(key);
        return Vue.$tAlt(label, label);
      },
      createWidgetEmbedSnippet(config: any, scriptQueries?: Record<string, string>) {
        const widgetAttributes = Object.keys(config)
          .filter((key) => config[key].value !== undefined)
          .map((key) => `data-${key}="${config[key].value}"`)
          .join('\n ');
        const queries = scriptQueries ? `?${Object.keys(scriptQueries).map((i) => `${i}=${scriptQueries[i]}`).join('&')}` : '';
        return `
          <link href="${this.widgetHost}/css/app.css" rel="stylesheet" type="text/css" />
          <div id="${config['mount-id'].value}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.widgetHost}"
              data-auth-apikey="GL-*****************************"
              src="${this.widgetHost}/js/app.js${queries}"
              ${widgetAttributes}
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      getQueriesFromWidgetConfig(config: WidgetConfig) {
        const queries = {} as Record<string, string>;
        Object.keys(config).forEach((key: any) => {
          const entry = (config as any)[key];
          if (entry.hidden) return;
          const value = entry?.value ?? entry?.default;
          const queryName = entry?.queryKey ?? Vue.$config.getQueryNameFromConfigKey(key);
          if (Vue._.isEmpty(value)) return;
          queries[queryName] = value!.toString();
        });
        return queries;
      },

      validateConfigInputs() {
        this.errorMessage = undefined;
        this.errorMessage = Object.keys(this.innerWidgetConfig)
          .map((key) => {
            const entry = (this.innerWidgetConfig as any)[key];
            if (entry.required && !entry.value) {
              return `${this.getConfigLabel(key, entry)}: ${this.$t('core.validation.required')}`;
            }
            return undefined;
          })
          .filter((error) => error)
          .join('\n');
        return !this.errorMessage;
      },

      async openWidget() {
        if (!this.validateConfigInputs()) {
          return;
        }
        if (this.widgetIsLoading) {
          return;
        }
        try {
          this.widgetIsLoading = true;

          const queries = this.getQueriesFromWidgetConfig(this.innerWidgetConfig);
          const queryString = Object.keys(queries)
            .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
            .join('&');
          this.$routerUtils.updateQueryParams(queries, { preserve: false });

          // Need to wait a tick for any subscribers to finish before updating the widget
          const widgetConfig = _.deepClone(this.innerWidgetConfig);
          this.$emit('loading', widgetConfig);
          this.$nextTick(() => {
            const route = (widgetConfig.route?.value as string)?.replace(/^\//, '');
            this.link = new URL(`${this.widgetHost}/${route}?${queryString}`, document.baseURI).href;
            this.widgetEmbedSnippet = this.createWidgetEmbedSnippet(widgetConfig, { cacheBust: Date.now().toString() });
            // Need to wait a tick for the mount div to become available
            this.$nextTick(() => {
              const resultElement = document.getElementById('widget-test-mount') as HTMLElement;
              resultElement.replaceChildren(document.createRange().createContextualFragment(this.widgetEmbedSnippet!));
              // TODO: Timeout is unreliable for this, find a better way to detect when the widget is loaded
              setTimeout(() => { this.widgetIsLoading = false; this.$emit('load-success'); }, 1000);
            });
          });
        } catch (error: undefined | any) {
          this.errorMessage = error.message;
          this.widgetIsLoading = false;
          this.$nextTick(() => {
            this.$emit('load-error');
          });
        }
        // This should only be called after the timeout (solve the timeout problem first)
        this.$nextTick(() => {
          this.$emit('load');
        });
      },
    },
  });
</script>
