<template>
  <p-container class="widget-tdgMap-testing-page page automation test">
    <il-widget-tester
      v-model="page.widgetConfig"
      title="Tour de Gwaerb Map"
    ></il-widget-tester>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import TdgMapTestingPage from './tdgMapTesting.vue.model';
  import IlWidgetTester from '../../../../../../components/il-widget-tester.vue';

  export default Vue.extend({
    name: 'CompanyRegistrationTesting',
    components: {
      IlWidgetTester,
    },
    data: () => ({
      page: new TdgMapTestingPage(),
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>

<style lang="scss">

  .glitWidgetElementHidden {
    display: none;
  }

  .glitWidgetElementVisible {
    display: block;
  }

</style>
