<template>
  <p-container class="widget-company-registration-testing-page page automation test">
    <il-widget-tester
      v-model="page.widgetConfig"
      title="Firmenanmeldungen Widget Test"
    ></il-widget-tester>
    <div id="widget-success" class="glitWidgetElementHidden">
      <h3 class="title">
        Ihre Firma wurde erfolgreich erfasst.
      </h3>
      <p>Wenn Sie wollen, können Sie sich jetzt in Ihrem Dashboard Verifizieren.</p>
      <p>Dazu benötigen Sie den Code, welchen wir auf ihre E-Mail gesendet haben.</p>
      <a href="/_exterior/login" target="_blank" rel="noopener noreferrer">
        <p-button>
          {{ $t('page.widget.button.redirectToIL') }}
        </p-button>
      </a>
    </div>

    <div id="widget-error" class="error glitWidgetElementHidden">
      <p>Leider ist bei der Übermittlung Ihrer Anmeldung oder Anfrage ein Fehler aufgetreten.</p>
    </div>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CompanyRegistrationTestingPage from './companyRegistrationTesting.vue.model';
  import IlWidgetTester from '../../../../../../components/il-widget-tester.vue';

  export default Vue.extend({
    name: 'CompanyRegistrationTesting',
    components: {
      IlWidgetTester,
    },
    role: ['Admin', 'Hostadmin'],
    auth: true,
    data: () => ({
      page: new CompanyRegistrationTestingPage(),
    }),
    mounted() {
      this.page.initialize();
    },
  });
</script>

<style lang="scss">

  .glitWidgetElementHidden {
    display: none;
  }

  .glitWidgetElementVisible {
    display: block;
  }

</style>
