import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import { GoogleMapsApi } from '@glittr/frontend-core/src/plugins/map/mapProvider/googleMapsProvider';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Tdg_CompanyDetailModel from '../../../../services/v2/model/tdg-company-detail-model';

export interface MyTourElement {
  Id: number,
  CompanyName: string,
  CompanyLogo?: string,
  CompanyJobs?: string,
  CountApprenticeships?: number,
  CompanyDescription?: string,
  CompanyStreet: string,
  CompanyHouseNumber: string,
  CompanyCity: string,
  CompanyPostalCode: string,
  CompanyContact: string,
  CompanyPhone: string,
  CompanyEmail: string,
  CompanyWebsite?: string,
}

export default class TdgMapPage extends PageBase {
  googleApi?: GoogleMapsApi;

  map?: google.maps.Map;

  showInfoDiv = false;

  showCompanyList = false;

  companyError = false;

  mapContainerElement = {} as HTMLElement;

  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  tourElement: Tdg_CompanyDetailModel | null = null;

  selectedCompanyId: number | undefined = undefined;

  public error?: Error | string = undefined;

  public myTourListItems: MyTourElement[] = [];

  get company() { return this.companiesDataSource?.data?.data ?? new Tdg_CompanyDetailModel(); }

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyDetailById,
  });

  mapItemDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Maps.getMapItems,
  });

  downloadPdfDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_PDF.downloadTdgPDF,
  });

  async initialize(): Promise<void> {
    this.myTourListItems = Vue.$sessionStorage.get<MyTourElement[]>('myTourElements') ?? [];
    this.googleApi = await Vue.$map.google.initialize('core', 'maps', 'marker', 'places');
  }

  async initializeTdgMap() {
    this.map = new this.googleApi!.maps.Map(
      this.mapContainerElement,
      {
        mapTypeId: this.googleApi!.maps.MapTypeId.SATELLITE,
        disableDefaultUI: false,
        zoom: 12,
        center: { lat: 47.103375, lng: 9.068674 },
        mapId: (Vue.$config.values as any)['google-mapsmapid'],
      },
    );
    this.findUserPosition();
  }

  findUserPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const userLatLng = new this.googleApi!.core.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );

          this.map = new this.googleApi!.maps.Map(
            this.mapContainerElement,
            {
              mapTypeId: this.googleApi!.maps.MapTypeId.SATELLITE,
              disableDefaultUI: false,
              zoom: 12,
              center: userLatLng,
              mapId: (Vue.$config.values as any)['google-mapsmapid'],
            },
          );

          Vue.$log.info(`User position: lat ${position.coords.latitude}, lng ${position.coords.longitude}`);

          await this.loadMarkerItems();
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              Vue.$log.error('Nutzer hat die Standortbestimmung abgelehnt.');
              break;
            case error.POSITION_UNAVAILABLE:
              Vue.$log.error('Standortinformationen sind nicht verfügbar.');
              break;
            case error.TIMEOUT:
              Vue.$log.error('Die Standortbestimmung hat zu lange gedauert.');
              break;
            default:
              Vue.$log.error('Ein unbekannter Fehler ist aufgetreten.');
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        },
      );
    } else {
      Vue.$log.error('Geolocation wird von diesem Browser nicht unterstützt.');
    }
  }

  async loadMarkerItems() {
    await this.mapItemDataSource.select();
    const mapItems = this.mapItemDataSource.data?.items;
    mapItems?.forEach((i) => {
      const marker = new this.googleApi!.marker.AdvancedMarkerElement({
        position: new this.googleApi!.core.LatLng(i.data.latitude!, i.data.longitude!),
        map: this.map,
        title: i.data.title,
      });
      marker.addListener('click', () => {
        if (i.data.id !== undefined) {
          this.showInfoBox(i.data.id);
        } else {
          Vue.$log.error('Firmendetails ID ist undefined.');
        }
      });
      this.markers.push(marker);
    });
  }

  addToMyTour(Id: number | undefined, element: Tdg_CompanyDetailModel) {
    if (Id === undefined) {
      Vue.$log.error('Cannot add to tour, Id is undefined.');
      return;
    }

    const existingElement = this.myTourListItems.find((item) => item.Id === Id);
    if (existingElement) {
      this.error = 'Diese Firma ist bereits in der Liste.';
      this.companyError = true;
      return;
    }

    const newTourElement = {
      Id,
      CompanyName: element.companyName,
      CompanyLogo: element.companyLogo,
      CompanyJobs: element.companyJobs,
      CountApprenticeships: element.companyApprenticeshipsCount,
      CompanyDescription: element.companyDescription,
      CompanyStreet: element.companyAddress?.street,
      CompanyHouseNumber: element.companyAddress?.houseNumber,
      CompanyCity: element.companyAddress?.city,
      CompanyPostalCode: element.companyAddress?.postalCode,
      CompanyContact: element.companyContactDetails?.contactName,
      CompanyPhone: element.companyContactDetails?.phoneNumber,
      CompanyEmail: element.companyContactDetails?.emailAddress,
      CompanyWebsite: element.companyContactDetails?.website,
    } as MyTourElement;
    this.myTourListItems.push(newTourElement);
    Vue.$sessionStorage.set('myTourElements', this.myTourListItems);
    this.showInfoDiv = false;
    this.showCompanyList = true;
  }

  removeItem(Id: number) {
    this.myTourListItems = this.myTourListItems.filter((item) => item.Id !== Id);
    Vue.$sessionStorage.set('myTourElements', this.myTourListItems);
    this.showCompanyList = true;
  }

  public async showInfoBox(companyId: number) {
    this.showInfoDiv = true;
    this.selectedCompanyId = companyId;
    try {
      this.companiesDataSource.filter.id = companyId;
      await this.companiesDataSource.select();

      this.tourElement = new Tdg_CompanyDetailModel({
        companyName: this.companiesDataSource.data?.data.companyName,
        companyAddress: this.companiesDataSource.data?.data.companyAddress,
        companyApprenticeshipsCount: this.companiesDataSource.data?.data.companyApprenticeshipsCount,
        companyContactDetails: this.companiesDataSource.data?.data.companyContactDetails,
        companyDescription: this.companiesDataSource.data?.data.companyDescription,
        companyJobs: this.companiesDataSource.data?.data.companyJobs,
        companyLogo: this.companiesDataSource.data?.data.companyLogo,
        organisationContactDetails: this.companiesDataSource.data?.data.organisationContactDetails,
        organisationLogo: null,
        organisationName: this.companiesDataSource.data?.data.organisationName,
      });
    } catch (error) {
      Vue.$log.error(`Fehler beim Abrufen der Firmendetails: ${error}`);
    }
  }

  async downloadPdf() {
    try {
      const storedTourData = Vue.$sessionStorage.get<MyTourElement[]>('myTourElements') ?? [];
      const requestData = storedTourData.map((company) => ({
        CompanyName: company.CompanyName,
        CompanyJobs: company.CompanyJobs || '',
        CompanyApprenticeships: company.CountApprenticeships?.toString() || '0',
        CompanyAdress: `${company.CompanyStreet} ${company.CompanyHouseNumber ? ` ${company.CompanyHouseNumber}` : ''}\n${company.CompanyPostalCode} ${company.CompanyCity}`,
        CompanyContact: `${company.CompanyContact}\n${company.CompanyPhone}\n${company.CompanyEmail}`,
      }));

      const response = await this.downloadPdfDataSource.select({
        body: requestData,
      });

      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Meine_Tour.pdf';
        link.click();
        Vue.$log.info('PDF erfolgreich heruntergeladen.');
      } else {
        Vue.$log.error('Es wurden keine PDF-Daten zurückgegeben.');
      }
    } catch (error) {
      Vue.$log.error(`Fehler beim Herunterladen des PDFs: ${error}`);
    }
  }

  public hideInfoBox() {
    this.showInfoDiv = false;
    this.companyError = false;
  }

  public getFormattedCompanyJobs(): string[] {
    return this.companiesDataSource.data?.data.companyJobs?.split('\n').map((job) => job.trim()) ?? [];
  }

  public toggleSideDrawer() {
    this.showCompanyList = !this.showCompanyList;
  }
}
