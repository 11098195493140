<template>
  <div class="organisationDashboard-page pa-6">
    <h1>{{ $t("page.dashboard.organisations.title") }} {{ page.organisationName }}</h1>
    <div class="dashboard-cards">
      <p-row>
        <p-col xs6 sm4 md3>
          <p-card class="fill-height">
            <div class="dashboard-card">
              <h4 class="mb-2">
                {{ $t("page.dashboard.organisations.daten.title") }}
              </h4>
              <p class="caption-1">
                {{ $t("page.dashboard.organisations.daten.text") }}
              </p>
            </div>
            <p-toolbar>
              <p-row>
                <p-button @click="$router.push('/tourdegwearb/organisation/edit')">
                  {{ $t("page.dashboard.organisations.daten.edit") }}
                </p-button>
              </p-row>
            </p-toolbar>
          </p-card>
        </p-col>
        <p-col xs6 sm4 md3>
          <p-card class="fill-height">
            <div class="dashboard-card">
              <h4 class="mb-2">
                {{ $t("page.dashboard.organisations.mitglieder.title") }}
              </h4>
              <p class="caption-1">
                {{ $t("page.dashboard.organisations.mitglieder.text") }}
              </p>
            </div>
            <p-toolbar>
              <p-row>
                <p-button @click="$router.push('/tourdegwearb/organisation/members')">
                  {{ $t("page.dashboard.organisations.mitglieder.liste") }}
                </p-button>
              </p-row>
            </p-toolbar>
          </p-card>
        </p-col>
        <p-col xs6 sm4 md3>
          <p-card class="fill-height">
            <div class="dashboard-card">
              <h4 class="mb-2">
                {{ $t("page.dashboard.organisations.zahlen.title") }}
              </h4>
              <p class="caption-1">
                {{ $t("page.dashboard.organisations.zahlen.text") }}
              </p>
              <div>
                <p-alert :value="true" type="info">
                  {{ $t("page.dashboard.organisations.zahlen.info") }}
                </p-alert>
              </div>
            </div>
          </p-card>
        </p-col>
        <p-col xs6 sm4 md3>
          <p-card class="fill-height">
            <div class="dashboard-card">
              <h4 class="mb-2">
                {{ $t("page.dashboard.organisations.support.title") }}
              </h4>
              <p>
                gl it gmbh<br>
                8758 Obstalden<br>
                <a :href="supportWebsite" target="_blank" rel="noopener noreferrer">{{ supportWebsite }}</a><br>
                <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
              </p>
            </div>
          </p-card>
        </p-col>
      </p-row>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import OrganisationDashboardPage from './organisationDashboard.vue.model';

  export default Vue.extend({
    auth: true,
    name: 'OrganisationDashboard',
    data: () => ({
      page: new OrganisationDashboardPage(),
      supportEmail: undefined as string | undefined,
      supportWebsite: undefined as string | undefined,
    }),
    mounted() {
      this.page.initialize();
      this.supportEmail = this.$config.values['support-email'];
      this.supportWebsite = this.$config.values['support-website'];
    },
  });
</script>
