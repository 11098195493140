// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetOrganisationLookupRequestModel from '../../model/get-organisation-lookup-request-model';
import GetOrganisationByIdRequestModel from '../../model/get-organisation-by-id-request-model';
import PutOrganisationRequestModel from '../../model/put-organisation-request-model';
import GetMyOrganisationRequestModel from '../../model/get-my-organisation-request-model';
import GetOrganisationsRequestModel from '../../model/get-organisations-request-model';
import PostOrganisationRequestModel from '../../model/post-organisation-request-model';
import ValidateOrganisationRequestModel from '../../model/validate-organisation-request-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import Tdg_OrganisationModel from '../../model/tdg-organisation-model';
import Tdg_OrganisationPagingResultModel from '../../model/tdg-organisation-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getOrganisationLookup(request: GetOrganisationLookupRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrganisationLookupRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-organisation-lookup'] ?? 'Tdg_Organisations/lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getOrganisationById(request: GetOrganisationByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrganisationByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-organisation-by-id'] ?? 'Tdg_Organisations/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_OrganisationModel.toModel(dto));
  },
  /**  */
  async putOrganisation(request: PutOrganisationRequestModel, config?: RequestConfig) {
    const requestDTO = PutOrganisationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-put-organisation'] ?? 'Tdg_Organisations/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        externalId: requestDTO.externalId,
        organisationName: requestDTO.organisationName,
        logoDocumentExternalId: requestDTO.logoDocumentExternalId,
        membersCount: requestDTO.membersCount,
        organisationContactSalutation: requestDTO.organisationContactSalutation,
        organisationContactFirstName: requestDTO.organisationContactFirstName,
        organisationContactLastName: requestDTO.organisationContactLastName,
        organisationContactPhoneNumber: requestDTO.organisationContactPhoneNumber,
        organisationContactEmail: requestDTO.organisationContactEmail,
        organisationStreet: requestDTO.organisationStreet,
        organisationHouseNumber: requestDTO.organisationHouseNumber,
        organisationZIP: requestDTO.organisationZIP,
        organisationCity: requestDTO.organisationCity,
        isActive: requestDTO.isActive,
        acceptConditions: requestDTO.acceptConditions,
        organisationUserId: requestDTO.organisationUserId,
        isSupporter: requestDTO.isSupporter,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getMyOrganisation(request: GetMyOrganisationRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-my-organisation'] ?? 'Tdg_Organisations/My';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_OrganisationModel.toModel(dto));
  },
  /**  */
  async getOrganisations(request: GetOrganisationsRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrganisationsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-organisations'] ?? 'Tdg_Organisations';
    const response = await service.get<any>(endpointPath, {
      query: {
        isActive: requestDTO.isActive,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_OrganisationPagingResultModel.toModel(dto));
  },
  /**  */
  async postOrganisation(request: PostOrganisationRequestModel, config?: RequestConfig) {
    const requestDTO = PostOrganisationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-organisation'] ?? 'Tdg_Organisations';
    const response = await service.post<any>(endpointPath, {
      body: {
        organisationName: requestDTO.organisationName,
        logoDocumentExternalId: requestDTO.logoDocumentExternalId,
        membersCount: requestDTO.membersCount,
        organisationContactSalutation: requestDTO.organisationContactSalutation,
        organisationContactFirstName: requestDTO.organisationContactFirstName,
        organisationContactLastName: requestDTO.organisationContactLastName,
        organisationContactPhoneNumber: requestDTO.organisationContactPhoneNumber,
        organisationContactEmail: requestDTO.organisationContactEmail,
        organisationStreet: requestDTO.organisationStreet,
        organisationHouseNumber: requestDTO.organisationHouseNumber,
        organisationZIP: requestDTO.organisationZIP,
        organisationCity: requestDTO.organisationCity,
        isActive: requestDTO.isActive,
        acceptConditions: requestDTO.acceptConditions,
        organisationUserId: requestDTO.organisationUserId,
        isSupporter: requestDTO.isSupporter,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_OrganisationModel.toModel(dto));
  },
  /**  */
  async validateOrganisation(request: ValidateOrganisationRequestModel, config?: RequestConfig) {
    const requestDTO = ValidateOrganisationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-validate-organisation'] ?? 'Tdg_Organisations';
    const response = await service.patch<any>(endpointPath, {
      body: {
        organisationName: requestDTO.organisationName,
        logoDocumentExternalId: requestDTO.logoDocumentExternalId,
        membersCount: requestDTO.membersCount,
        organisationContactSalutation: requestDTO.organisationContactSalutation,
        organisationContactFirstName: requestDTO.organisationContactFirstName,
        organisationContactLastName: requestDTO.organisationContactLastName,
        organisationContactPhoneNumber: requestDTO.organisationContactPhoneNumber,
        organisationContactEmail: requestDTO.organisationContactEmail,
        organisationStreet: requestDTO.organisationStreet,
        organisationHouseNumber: requestDTO.organisationHouseNumber,
        organisationZIP: requestDTO.organisationZIP,
        organisationCity: requestDTO.organisationCity,
        isActive: requestDTO.isActive,
        acceptConditions: requestDTO.acceptConditions,
        organisationUserId: requestDTO.organisationUserId,
        isSupporter: requestDTO.isSupporter,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_OrganisationModel.toModel(dto));
  },
});
