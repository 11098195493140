// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Tdg_OrganisationModel from '../../model/tdg-organisation-model';
import Tdg_OrganisationDTO from '../dto/tdg-organisation-dto';
import dtoSchema from '../schemas/tdg-organisation-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class Tdg_OrganisationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Tdg_OrganisationDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.externalId = data.externalId;
      this.organisationName = data.organisationName;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.membersCount = data.membersCount;
      this.organisationContactSalutation = data.organisationContactSalutation;
      this.organisationContactFirstName = data.organisationContactFirstName;
      this.organisationContactLastName = data.organisationContactLastName;
      this.organisationContactPhoneNumber = data.organisationContactPhoneNumber;
      this.organisationContactEmail = data.organisationContactEmail;
      this.organisationStreet = data.organisationStreet;
      this.organisationHouseNumber = data.organisationHouseNumber ?? undefined;
      this.organisationZIP = data.organisationZIP;
      this.organisationCity = data.organisationCity;
      this.isActive = data.isActive;
      this.acceptConditions = data.acceptConditions;
      this.organisationUserId = data.organisationUserId ?? undefined;
      this.isSupporter = data.isSupporter;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {uuid}
  */
  externalId?: string;
  /**
  * @minimum 1
  */
  organisationName?: string;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  * @type {int32}
  */
  membersCount?: number;
  /**
  * @type {int32}
  */
  organisationContactSalutation?: 0 | 1 | 2; // SalutationDTO
  /**
  * @minimum 1
  */
  organisationContactFirstName?: string;
  /**
  * @minimum 1
  */
  organisationContactLastName?: string;
  /**
  * @minimum 1
  */
  organisationContactPhoneNumber?: string;
  /**
  * @minimum 1
  */
  organisationContactEmail?: string;
  /**
  * @minimum 1
  */
  organisationStreet?: string;
  /**
  */
  organisationHouseNumber?: string;
  /**
  * @minimum 1
  */
  organisationZIP?: string;
  /**
  * @minimum 1
  */
  organisationCity?: string;
  /**
  */
  isActive?: boolean;
  /**
  */
  acceptConditions?: boolean;
  /**
  * @type {int64}
  */
  organisationUserId?: number;
  /**
  */
  isSupporter?: boolean;

  static toModel(dto: DeepPartial<Tdg_OrganisationDTO>): Tdg_OrganisationModel;
  static toModel(dto: DeepPartial<Tdg_OrganisationDTO> | undefined | null): Tdg_OrganisationModel | undefined;
  static toModel(dto: DeepPartial<Tdg_OrganisationDTO> | undefined | null): Tdg_OrganisationModel | undefined {
    return dto ? new Tdg_OrganisationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Tdg_OrganisationModel> | Tdg_OrganisationModel): Tdg_OrganisationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      externalId: unwrapped.externalId,
      organisationName: unwrapped.organisationName,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      membersCount: unwrapped.membersCount,
      organisationContactSalutation: unwrapped.organisationContactSalutation,
      organisationContactFirstName: unwrapped.organisationContactFirstName,
      organisationContactLastName: unwrapped.organisationContactLastName,
      organisationContactPhoneNumber: unwrapped.organisationContactPhoneNumber,
      organisationContactEmail: unwrapped.organisationContactEmail,
      organisationStreet: unwrapped.organisationStreet,
      organisationHouseNumber: unwrapped.organisationHouseNumber,
      organisationZIP: unwrapped.organisationZIP,
      organisationCity: unwrapped.organisationCity,
      isActive: unwrapped.isActive,
      acceptConditions: unwrapped.acceptConditions,
      organisationUserId: unwrapped.organisationUserId,
      isSupporter: unwrapped.isSupporter,
    } as Tdg_OrganisationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Tdg_OrganisationModel, can not map to Tdg_OrganisationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
