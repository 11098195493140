import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';

export default class LayoutDefaultPage extends PageBase {
  public isOrganisation = false;

  public isCompany = false;

  async initialize(): Promise<void> {
    this.isOrganisation = await Vue.$service.v2.api.tdg_Users.amIOrganisation();
    this.isCompany = await Vue.$service.v2.api.tdg_Users.amICompany();
  }
}
