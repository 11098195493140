import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import Tdg_OrganisationModel from '../../../../services/v2/model/tdg-organisation-model';
import personalDetailsVueSchema from './editOrganisations.vue.schema';
import GetTdgDocumentByExternalIdRequestModel from '../../../../services/v2/model/get-tdg-document-by-external-id-request-model';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';

export default class EditOrganisationsPage extends PageBase {
  public get organisation() { return this.organisationsDataSource?.data?.data ?? new Tdg_OrganisationModel(); }

  public error?: Error | string = undefined;

  public organisationId: number | undefined = undefined;

  public adminMode: boolean = false;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public organisationsDataSourceAdmin = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisationById,
    updateCommand: Vue.$service.v2.api.tdg_Organisations.putOrganisation,
  });

  public organisationsDataSourceMy = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getMyOrganisation,
    updateCommand: Vue.$service.v2.api.tdg_Organisations.putOrganisation,
  });

  private organisationsDataSource?: typeof this.organisationsDataSourceAdmin | typeof this.organisationsDataSourceMy = undefined;

  async initialize(): Promise<void> {
    if (this.adminMode) {
      this.organisationsDataSourceAdmin.filter.id = this.organisationId;
      this.organisationsDataSource = this.organisationsDataSourceAdmin;
    } else {
      this.organisationsDataSource = this.organisationsDataSourceMy;
    }
    await this.organisationsDataSource.select();
    if (this.organisation.logoDocumentExternalId) {
      const requestDocument = new GetTdgDocumentByExternalIdRequestModel();
      requestDocument.externalId = this.organisation.logoDocumentExternalId;
      const document = await Vue.$service.v2.api.tdg_Documents.getTdgDocumentByExternalId(requestDocument);
      const fileContent = await Vue.$service.v2.api.tdg_Documents.downloadTdgDocument(requestDocument);
      const file = new File([fileContent.data], document.data.originalFileName || 'unknown Filename', { type: document.data.contentType });
      Vue.set(this, 'logoDocument', [file]);
    }
  }

  public editOrganisations = async () => {
    const valid = this.organisation.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadTdgDocumentRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
        this.organisation.logoDocumentExternalId = documentId.data;
      } else {
        this.organisation.logoDocumentExternalId = undefined;
      }
      try {
        await this.organisationsDataSource?.update(this.organisation);
        Vue.$router.back();
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };
}
