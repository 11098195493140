import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema: IJSONSchema = {
  type: 'object',
  properties: {
    company: {
      type: 'object',
      properties: {
        accessToken: { type: 'string' },
        companyName: { type: 'string', minLength: 1 },
        companyStreet: { type: 'string', minLength: 1 },
        companyZIP: { type: 'string', minLength: 1 },
        companyCity: { type: 'string', minLength: 1 },
        companyContactSalutation: { type: 'integer', minimum: 0 },
        companyContactFirstName: { type: 'string', minLength: 1 },
        companyContactLastName: { type: 'string', minLength: 1 },
        companyContactPhoneNumber: { type: 'string', pattern: validationPatterns.phone },
        companyContactEmail: { type: 'string', pattern: validationPatterns.email },
        isNotDefaultLocation: {
          type: 'boolean',
          enum: [true],
          title: 'Standort',
        },
        acceptConditions: {
          type: 'boolean',
          enum: [true],
        },
      },
      required: [
        'accessToken', 'companyName', 'companyStreet', 'companyZIP', 'companyCity', 'companyContactSalutation', 'companyContactFirstName', 'companyContactLastName', 'companyContactPhoneNumber', 'companyContactEmail', 'isDefaultLocation', 'acceptConditions',
      ],
    },
    user: {
      type: 'object',
      properties: {
        email: { type: 'string', pattern: validationPatterns.email },
        password: { type: 'string', minLength: 6 },
        repeatPassword: { type: 'string', minLength: 6 },
      },
      required: ['email', 'password', 'repeatPassword'],
    },
  },
  required: ['company', 'user'],
};

export default personalDetailsVueSchema;
