<template>
  <div class="tdgMapWrapper">
    <div id="tdgMap" ref="tdgMap" class="map"></div>
    <div class="overlayWrapper" :class="{ show: page.showInfoDiv }">
      <div class="overlay"></div>
      <p-overlay>
        <p-request-alert v-model="page.companiesDataSource.error"></p-request-alert>
        <p-button class="hideInfoBoxButton" @click="page.hideInfoBox()"></p-button>
        <div v-if="page.companiesDataSource.data" class="infoBoxWrapper">
          <div class="companyDetailsWrapper">
            <div class="companyProfileDetailsWrapper">
              <h4 class="title">
                {{ page.company.companyName }}
              </h4>
              <p-img v-if="page.company.companyLogo" :src="`data:image/jpeg;base64,${page.company.companyLogo}`" alt="Logo"></p-img>
            </div>
            <div v-if="page.getFormattedCompanyJobs().length > 0" class="companyDetailsJobsWrapper">
              <p class="subtitle">
                {{ $t('page.map.overlay.jobs') }}:
              </p>
              <p class="jobsWrapper">
                <ul class="jobsList">
                  <li v-for="(job, index) in page.getFormattedCompanyJobs()" :key="index">
                    {{ job }}
                  </li>
                </ul>
              </p>
            </div>
            <div v-if="page.company.companyApprenticeshipsCount">
              <p class="apprenticeshipsWrapper">
                {{ page.company.companyApprenticeshipsCount }} <span class="subtitle">
                  {{ $tEnum('page.map.overlay.apprenticeshipCount', page.company.companyApprenticeshipsCount > 1 ? 'multiple' : 'one') }}
                </span>
              </p>
            </div>

            <div v-if="page.company.companyDescription" class="companyDetailsDescriptionWrapper">
              <p class="subtitle description">
                {{ $t('page.map.overlay.companyDescription') }}:
              </p>
              <p>{{ page.company.companyDescription }}</p>
            </div>
          </div>
          <div v-if="page.company.companyAddress" class="companyAdressWrapper">
            <p class="subtitle">
              {{ $t('page.map.overlay.companyAdress') }}:
            </p>
            <p>{{ page.company.companyAddress.street }} {{ page.company.companyAddress.houseNumber }}</p>
            <p>{{ page.company.companyAddress.postalCode }} {{ page.company.companyAddress.city }}</p>
          </div>
          <div v-if="page.company.companyContactDetails" class="companyContactWrapper">
            <p class="subtitle">
              {{ $t('page.map.overlay.companyContact') }}:
            </p>
            <p>{{ page.company.companyContactDetails.contactName }}</p>
            <p v-if="page.company.companyContactDetails.phoneNumber">
              <a :href="`tel:${page.company.companyContactDetails.phoneNumber}`" class="phone-link">
                {{ formatPhoneNumber(page.company.companyContactDetails.phoneNumber) }}
              </a>
            </p>
            <p>
              <a :href="`mailto:${page.company.companyContactDetails.emailAddress}`" class="email-link">{{ page.company.companyContactDetails.emailAddress }}</a>
            </p>
            <p v-if="page.company.companyContactDetails.website">
              <a :href="getFullUrl(page.company.companyContactDetails.website)" target="_blank" rel="noopener noreferrer" class="website-link">
                {{ page.company.companyContactDetails.website }}
              </a>
            </p>
          </div>
          <div class="buttonWrapper">
            <p-request-alert v-if="page.companyError" v-model="page.error" class="companyError"></p-request-alert>
            <p-button class="addToMyTour" @click="page.tourElement ? page.addToMyTour(page.selectedCompanyId, page.tourElement) : null">
              {{ $t('page.map.button.addToMyTour') }}
            </p-button>
          </div>
          <div v-if="page.company.organisationContactDetails" class="organisationWrapper">
            <div class="organisationProfileDetailsWrapper">
              <p class="subtitle organisationText">
                {{ $t('page.map.overlay.belongsTo') }}:
              </p>
              <p class="organisationText">
                {{ page.company.organisationName }}
              </p>
              <p-img v-if="page.company.organisationLogo" :src="`data:image/jpeg;base64,${page.company.organisationLogo}`" alt="Logo"></p-img>
            </div>
          </div>
        </div>
      </p-overlay>
    </div>
    <div class="myTourSideDrawerWrapper" :class="{ extended: page.showCompanyList }">
      <div class="myTourSideDrawerToggle">
        <p-button @click="page.toggleSideDrawer()">
          <p-icon>{{ page.showCompanyList ? 'arrow_forward_ios' : 'arrow_back_ios' }}</p-icon>
        </p-button>
      </div>
      <div class="myTourSideDrawerContent">
        <h3 class="title tourTitle">
          {{ $t('page.map.list.title') }}
        </h3>
        <div v-if="page.myTourListItems.length > 0" class="pdfButtonsWrapper">
          <p-button class="downloadPdf" @click="page.downloadPdf()">
            PDF Herunterladen
          </p-button>
        </div>
        <div class="companyListWrapper">
          <p-list-group>
            <p-list>
              <p-list-entry v-for="element in page.myTourListItems" :key="element.Id">
                <p-list-group>
                  <template #activator>
                    <div class="listElements">
                      <p class="companyName">
                        {{ element.CompanyName }}
                      </p>
                      <div class="companyLogoWrapper">
                        <p-img v-if="element.CompanyLogo" :src="`data:image/jpeg;base64,${element.CompanyLogo}`" alt="Logo" class="companyLogo"></p-img>
                      </div>
                      <div v-if="element.CompanyJobs" class="companyJobsWrapper">
                        <div class="jobsWrapper">
                          <ul class="unorderedJobsList">
                            <li v-for="job in element.CompanyJobs.split('\n')" :key="job" class="jobsList">
                              {{ job }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <p-button class="deleteButton" @click="page.removeItem(element.Id)"></p-button>
                  </template>
                  <p-container class="listEntryContainer">
                    <div class="companyInfoWrapper">
                      <div v-if="element.CountApprenticeships" class="listCompanyDetailsWrapper">
                        <p>
                          {{ element.CountApprenticeships }} <span class="subtitle">
                            {{ $tEnum('page.map.overlay.apprenticeshipCount', element.CountApprenticeships > 1 ? 'multiple' : 'one') }}
                          </span>
                        </p>
                      </div>
                      <div class="listCompanyAdressWrapper">
                        <p class="subtitle">
                          {{ $t('page.map.overlay.companyAdress') }}:
                        </p>
                        <p>{{ element.CompanyStreet }} {{ element.CompanyHouseNumber }}</p>
                        <p>{{ element.CompanyPostalCode }} {{ element.CompanyCity }}</p>
                      </div>
                      <div class="listCompanyContactWrapper">
                        <p class="subtitle">
                          {{ $t('page.map.overlay.companyContact') }}:
                        </p>
                        <p>{{ element.CompanyContact }}</p>
                        <p>
                          <a :href="`tel:${element.CompanyPhone}`" class="phone-link">
                            {{ formatPhoneNumber(element.CompanyPhone) }}
                          </a>
                        </p>
                        <p>
                          <a :href="`mailto:${element.CompanyEmail}`" class="email-link">{{ element.CompanyEmail }}</a>
                        </p>
                        <p v-if="element.CompanyWebsite">
                          <a :href="getFullUrl(element.CompanyWebsite)" target="_blank" rel="noopener noreferrer" class="website-link">
                            {{ element.CompanyWebsite }}
                          </a>
                        </p>
                      </div>
                    </div>
                  </p-container>
                </p-list-group>
              </p-list-entry>
            </p-list>
          </p-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import TdgMapPage from './tdgMap.vue.model';

  export default Vue.extend({
    name: 'TdgMap',
    layout: 'layout-widget',
    data: () => ({
      page: new TdgMapPage(),
    }),
    async mounted() {
      await this.page.initialize();
      this.page.mapContainerElement = this.$refs.tdgMap as HTMLElement;
      await this.page.initializeTdgMap();
      await this.page.loadMarkerItems();
    },
    methods: {
      getFullUrl(url: string) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `http://${url}`;
        }
        return url;
      },
      formatPhoneNumber(phoneNumber: string) {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const formatted = cleaned.replace(/^(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/, '+$1 $2 $3 $4 $5');
        return formatted;
      },
    },
  });
</script>
