import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import { ConfigType } from '@core/plugins/config/config';
import _ from '@glittr/frontend-core/src/utils';
import personalDetailsVueSchema from './organisationRegistration.vue.schema';
import OrganisationAndUserRequestModelBase from '../../../../services/v2/model/organisation-and-user-request-model';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';

export default class OrganisationRegistrationPage extends PageBase {
  public error?: Error | string = undefined;

  public emailError: string | null = null;

  public logoDocuments: undefined | File[];

  public isLoading = false;

  public combinedModels = new OrganisationAndUserRequestModelBase();

  public registrationSuccess = false;

  public supporterAmount: number | undefined;

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  async initialize(): Promise<void> {
    // Not used
  }

  public organisationsDataSource = new DataSource({
    insertCommand: Vue.$service.v2.api.tdg_Organisations.postOrganisation,
  });

  public usersDataSource = new DataSource({
    insertCommand: Vue.$service.v2.api.users.register,
  });

  public tdg_usersDataSource = new DataSource({
    insertCommand: Vue.$service.v2.api.tdg_Users.postUser,
  });

  public saveNewOrganisations = async () => {
    if (this.combinedModels.user.email == null) {
      this.combinedModels.user.email = this.combinedModels.organisation.organisationContactEmail;
    }
    this.combinedModels.user.userName = this.combinedModels.user.email;
    const valid = this.combinedModels.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      if (this.combinedModels.user.repeatPassword !== this.combinedModels.user.password) {
        this.error = Vue.$t('page.widget.error.passwordsDoNotMatch');
        return;
      }
      this.isLoading = true;
      try {
        const logoFiles = this.logoDocuments ?? [];
        if (logoFiles.length > 0) {
          const request = new UploadTdgDocumentRequestModel();
          request.uploadFile = this.logoDocuments?.[0];
          const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
          this.combinedModels.organisation.logoDocumentExternalId = documentId.data;
        }
        await Vue.$service.v2.api.tdg_Organisations.validateOrganisation(this.combinedModels.organisation);
        const userResult = await this.usersDataSource.insert(this.combinedModels.user);
        this.combinedModels.organisation.organisationUserId = userResult.data;
        this.combinedModels.organisation.isActive = true;
        this.combinedModels.tdg_User.registrationType = 'Organisation';
        this.combinedModels.tdg_User.userId = userResult.data;
        await this.organisationsDataSource.insert(this.combinedModels.organisation);
        await this.tdg_usersDataSource.insert(this.combinedModels.tdg_User);
        this.registrationSuccess = true;
        this.showExternalElement('widget-successelementid');
        this.isLoading = false;
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
        this.isLoading = false;
      }
    }
  };

  public getExternalElement(configKey: keyof ConfigType) {
    const externalElementId = Vue.$config.getQueryOrValue(configKey);
    if (!_.isEmpty(externalElementId)) {
      const externalElement = document.getElementById(externalElementId!);
      if (externalElement === null) {
        Vue.$log.warning(`Element with id '${externalElementId}' not found.`);
      } else {
        return externalElement;
      }
    }
    return undefined;
  }

  public showExternalElement(configKey: keyof ConfigType) {
    const elem = this.getExternalElement(configKey);
    elem?.classList.add('glitWidgetElementVisible');
  }

  public hideExternalElement(configKey: keyof ConfigType) {
    const elem = this.getExternalElement(configKey);
    elem?.classList.remove('glitWidgetElementVisible');
  }
}
