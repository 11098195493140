// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DownloadTdgPDFRequestModel from '../../model/download-tdg-pdf-request-model';

export default (service: Servicelayer) => ({
  /**  */
  async downloadTdgPDF(request: DownloadTdgPDFRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadTdgPDFRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-tdg-pdf'] ?? 'Tdg_PDF/DownloadTdgPDF';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: {
        companyName: requestDTO.companyName,
        companyJobs: requestDTO.companyJobs,
        companyApprenticeships: requestDTO.companyApprenticeships,
        companyAdress: requestDTO.companyAdress,
        companyContact: requestDTO.companyContact,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
