// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Tdg_ContactDetailsModel from '../../model/tdg-contact-details-model';
import Tdg_ContactDetailsDTO from '../dto/tdg-contact-details-dto';
import dtoSchema from '../schemas/tdg-contact-details-schema';

export default abstract class Tdg_ContactDetailsModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Tdg_ContactDetailsDTO>) {
    super();
    if (data) {
      this.contactName = data.contactName ?? undefined;
      this.phoneNumber = data.phoneNumber ?? undefined;
      this.emailAddress = data.emailAddress ?? undefined;
      this.website = data.website ?? undefined;
    }
  }
  /**
  */
  contactName?: string;
  /**
  */
  phoneNumber?: string;
  /**
  */
  emailAddress?: string;
  /**
  */
  website?: string;

  static toModel(dto: DeepPartial<Tdg_ContactDetailsDTO>): Tdg_ContactDetailsModel;
  static toModel(dto: DeepPartial<Tdg_ContactDetailsDTO> | undefined | null): Tdg_ContactDetailsModel | undefined;
  static toModel(dto: DeepPartial<Tdg_ContactDetailsDTO> | undefined | null): Tdg_ContactDetailsModel | undefined {
    return dto ? new Tdg_ContactDetailsModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Tdg_ContactDetailsModel> | Tdg_ContactDetailsModel): Tdg_ContactDetailsDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      contactName: unwrapped.contactName,
      phoneNumber: unwrapped.phoneNumber,
      emailAddress: unwrapped.emailAddress,
      website: unwrapped.website,
    } as Tdg_ContactDetailsDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Tdg_ContactDetailsModel, can not map to Tdg_ContactDetailsDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
