// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import Tdg_AddressSchema from './tdg-address-schema';
import Tdg_ContactDetailsSchema from './tdg-contact-details-schema';

const schema = {
  $id: '/tdg-company-detail-schema',
  type: 'object',
  required: [],
  properties: {
    companyName: {
      type: 'string',
      nullable: true,
    },
    companyAddress: {
      ...Tdg_AddressSchema,
    },
    companyContactDetails: {
      ...Tdg_ContactDetailsSchema,
    },
    companyLogo: {
      type: 'string',
      nullable: true,
    },
    companyJobs: {
      type: 'string',
      nullable: true,
    },
    companyApprenticeshipsCount: {
      type: 'integer',
      nullable: true,
    },
    companyDescription: {
      type: 'string',
      nullable: true,
    },
    organisationName: {
      type: 'string',
      nullable: true,
    },
    organisationLogo: {
      type: 'string',
      nullable: true,
    },
    organisationContactDetails: {
      ...Tdg_ContactDetailsSchema,
    },
  },
} as IJSONSchema;

export default schema;
